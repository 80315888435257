@import './variables';

.image-page-box {
  text-align: center;
  font-size: 2rem;
  color: $text-color-light-grey;
  margin: auto;
  padding-top: 5rem;
  padding-right: 3rem;
  .content-wrap {
    position: relative;
  }

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 22rem;
    width: 22rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }

  .text {
    position: relative;
    display: inline-block;
    padding-bottom: 2rem;
    color: $text-color-black;
    margin-top: 15rem;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: $text-color-dark-3;
    max-width: 680px;
    strong {
      font-family: 'Roboto', sans-serif;
      display: block;
      font-weight: 500;
      font-size: 1.7rem;
    }
  }
  strong {
    color: #000;
    font-weight: 400;
    font-size: 2rem;
  }
  p {
    padding-top: 10px;
    font-weight: 300;
    font-size: 1.6rem;
    &.thin {
      margin-top: 40px;
      color: rgb(161, 161, 161);
    }
  }

  .file-name-wrap {
    @include flexbox();
    @include justify-content(center);
    padding-bottom: 10px;
    font-size: 1.6rem;
    min-height: 38px;
    line-height: 38px;
    max-width: 100%;
    word-break: break-all;

    .menu-icon {
      @include flex-shrink(0);
      margin-right: 5px;
      height: 38px;
    }
  }

  .fv-loader {
    display: block;
    width: 100px;
    height: 60px;
    margin: auto;
    background-size: auto 100%;
    background-position: 50% 50%;
  }
  // Components that are using image-page-box layout
  &.no-files {
    .image-container {
      display: block;
      position: relative;
      height: 16rem;
      width: 16rem;
      padding-right: 0;
      padding-left: 0;
    }

    .text {
      margin-top: 0;
    }

    p {
      color: #000000;
      padding-bottom: 3rem;
    }

    .empty-folder-image {
      cursor: pointer;
      background-image: url('../assets/images/empty-icons/empty_folder.svg');
    }

    .empty-sfolder-image {
      background-image: url('../assets/images/empty-icons/empty-sfolder.svg');
      background-size: 100% auto;
    }

    .empty-recent-image {
      background-image: url('../assets/images/empty-icons/empty_recent.svg');
    }

    .empty-search-image {
      background-image: url('../assets/images/empty-icons/empty_search.svg');
    }

    .empty-bin-image {
      background-image: url('../assets/images/empty-icons/empty_bin_new.svg');
    }

    .empty-bp-image {
      background-image: url('../assets/images/empty-icons/empty_backup_new.svg');
      background-size: 100% auto;
    }

    .empty-shared-image {
      background-image: url('../assets/images/empty-icons/empty_shared.svg');
      background-size: 100% auto;
    }
  }

  &.refresh-page {
    a {
      color: #126de2;
      text-decoration: underline;
    }
  }

  &.file-version {
    /* TODO unify .content-loading for all views */
    &.content-loading {
      height: 100%;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      .content-wrap {
        height: 80%;
      }

      .fv-loader {
        position: absolute;
        top: -70px;
        bottom: 0;
        width: 100%;
      }
    }

    .file-version-img {
      background-image: url('../assets/images/MDWEB_DownloadVersion.svg');
    }
    .file-version-error-img {
      background-image: url('../assets/images/MDWEB_FileMissing.svg');
    }

    .version-info {
      font-size: 1.4rem;
      padding-bottom: 20px;
      span {
        display: inline-block;
        padding: 0 2px;
      }
    }

    .button {
      max-width: 280px;
    }
  }

  &.sh-link {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding-top: 2rem;

    &.sh-banner {
      @include flexbox();
      @include flex-direction(column);
    }

    .fv-loader {
      position: absolute;
      top: -70px;
      bottom: 0;
      width: 100%;
    }

    .content-wrap {
      box-sizing: border-box;
      padding-bottom: 15px;
      @include flex-grow(1);
    }

    .text {
      margin-top: 15rem;
    }

    .image-container {
      .img-thumb {
        display: block;
        width: 180px;
        height: 180px;

        border-radius: 5px;
        box-shadow: 0 0 10px 0px #777;
        margin: 0 auto auto;
        cursor: pointer;

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: #fff;

        &.not-found {
          margin: 4rem auto auto;
          background-image: url('../assets/images/ghost-404.svg');
          background-color: transparent;
          background-size: 40rem;
          width: 24rem;
          height: 24rem;
          &:before {
            display: none;
          }
        }
        &.fae-in-bin {
          margin: 4rem auto auto;
          background-image: url('../assets/images/owners-bin.svg');
          background-color: transparent;
          background-size: 100%;
          width: 180px;
          height: 180px;
          &:before {
            display: none;
          }
        }
        &.fae-upload-not-finished {
          margin: 4rem auto auto;
          background-image: url('../assets/images/please-wait.svg');
          background-color: transparent;
          background-size: 100%;
          width: 180px;
          height: 180px;
          &:before {
            display: none;
          }
        }

        &.backup-img {
          box-shadow: none;
          background-color: transparent;
        }

        &:before {
          content: '';
          background: #f6f6f6;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
          margin: auto;
          left: 0;
          display: block;
        }
      }
    }

    .version-info {
      font-weight: 400;
      font-size: 19px;
      padding-bottom: 2rem;
      color: #333333;
    }

    .button {
      width: 280px;
    }

    .open-width {
      font-size: 16px;
      color: $text-color-open-width;
    }

    .separator {
      border-bottom: 1px solid #c7c7c7;
      width: 280px;
      padding-top: 1rem;
      margin: auto auto 16px;
    }

    .open-file-footer {
      padding-top: 1rem;
      padding-bottom: 2rem;
      .open-with-ofs {
        padding-bottom: 0;
        .menu-icon {
          margin-right: 15px;
        }
        .file-name {
          font-size: 1.3rem;
          font-weight: 500;
          color: #216ce8;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
      }

      .officesuite-links {
        font-size: 1.3rem;
        color: #7a7a7a;

        .install-office-links {
          font-weight: 500;
          color: #216ce8;
          &.free {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.rtl {
  .image-page-box {
    padding-right: 0rem;
    padding-left: 3rem;
    &.file-version {
      .file-name-wrap {
        .menu-icon {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .image-page-box {
    &.no-files {
      p {
        padding-bottom: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .image-page-box {
    padding-right: 2rem;
    padding-top: 3rem;

    &.sh-link {
      padding-top: 1rem;
      &.sh-banner {
        min-height: calc(100% - 55px);
      }
    }
  }

  .mobile-device {
    .image-page-box {
      &.sh-link {
        .text {
          margin-top: 20rem;
        }

        .image-container .img-thumb {
          margin-top: 3rem;
        }
      }
    }
  }

  .rtl {
    .image-page-box {
      padding-right: 0rem;
      padding-left: 2rem;
    }
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .image-page-box {
    .text {
      margin-top: 10rem;
      bottom: 0;
      font-size: 1.6rem;
    }

    &.no-files {
      .empty-folder-image {
        height: 18rem;
        width: 20rem;
      }
    }
  }

  .mobile-device {
    .image-page-box {
      &.file-version,
      &.sh-link {
        padding-top: 1rem;

        .image-container .img-thumb {
          margin-top: 1rem;
        }
        .not-found-text {
          margin-top: 15rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mobile-device {
    .image-page-box {
      height: 100%;
      @include flexbox();
      @include flex-direction(column);

      &.sh-link {
        .image-container {
          height: 21rem;
          width: 21rem;
          .img-thumb {
            width: 152px;
            height: 152px;
            margin-top: 2rem;

            &.not-found {
              width: 200px;
              height: 200px;
              background-size: 28rem;
              margin-top: 6rem;
            }
          }
        }

        .text {
          margin-top: 16rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .image-page-box {
    padding: 0 10px;
    .text {
      margin-top: 12rem;
      font-size: 1.6rem;
    }

    .file-name-wrap {
      font-size: 1.3rem;
      min-height: 28px;
      line-height: 28px;
      .file-name {
        padding-top: 5px;
      }
    }

    &.no-files {
      padding-top: 3rem;
    }

    &.file-version,
    &.sh-link {
      .version-info {
        font-size: 1.2rem;
      }
    }
  }
}
