@charset "utf-8";

/*! reset.css v2.0.0 | MobiSystems | WebTeam \m/(-_-)\m/ */

/* #Reset & Basics 
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  vertical-align: baseline;
  zoom: 1;
}

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: Arial, Helvetica, sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  overflow-wrap: break-word;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* #Links
================================================== */
a {
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover,
a:focus {
  text-decoration: underline;
  outline: 0;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers.
================================================== */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* #Buttons and inputs
================================================== */

button,
input,
select,
textarea {
  -webkit-appearance: none;
}

button,
input {
  line-height: normal;
}

textarea {
  resize: none;
  overflow: auto;
  vertical-align: top;
}

select {
  padding: 0;
}

input[type="submit"], /* disable html5 validation red border (delete this if you dont need it!!!) */
input:invalid {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
textarea,
select {
  max-width: 100%;
  display: block;
  outline: none;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-offset: 0px;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

button::-moz-focus-inner, /* Fix for odd Mozilla border & padding issues */
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/* #Lists
================================================== */
// ul, ol{list-style-type:none}
// article ul{list-style-type:disc}
// article ol{list-style: decimal}
// article ul,article ol{margin:0px 0px 20px 20px}
// article ul ul, article ul ol, article ol ol, article ol ul {margin:5px 0px 5px 20px;font-size:90%}

/* #Clear tools
================================================== */
.clear {
  clear: both;
  height: 0px;
  width: 100%;
  display: block;
}
.cfix:after,
.cfix:before {
  content: ' ';
  display: block;
  height: 0;
  visibility: hidden;
}
.cfix:after {
  clear: both;
}
.cfix {
  zoom: 1;
}
.container:after {
  content: ' ';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
