@charset "utf-8";

/* #Reusable Styles
================================================== */
.bold {
  font-weight: 700 !important;
}

.action {
  color: $text-color-active;
  cursor: pointer;
}
.hide-element {
  display: none !important;
}

/* # Drop Down Menu
================================================== */
.dd-menu {
  position: relative;
  font-size: 0;

  .dd-menu-head {
    position: relative;
    padding: 5px 28px 5px 8px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    font-size: initial;

    @include no-selection-highlight();
    @include transition(background-color 0.2s linear);

    &:before {
      content: '';
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 0;
      height: 0;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid #5a676b;
    }

    &.no-menu-items {
      padding: 5px 8px;
      &:before {
        display: none;
      }
    }

    &:hover {
      background-color: $menu-hover-bgr;
    }
  }

  .dd-menu-hidden {
    display: none;
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    z-index: 0;
    list-style-type: none;
    font-weight: 400;
    font-size: initial;

    border-radius: 3px;
    box-shadow: 0 1px 29px 0 rgba(0, 0, 0, 0.15);
    background-color: $menu-bgr;
    border: solid 1px $menu-border-color;
    padding: 5px;

    &.position-top {
      top: auto;
      bottom: 100%;
    }

    > li {
      display: block;

      &.context-menu-item.applied:not(:last-child) {
        position: relative;
        padding-bottom: 2px;
        margin-bottom: 2px;
        margin-right: -5px;
        padding-right: 4px;
        border-bottom: 1px solid $menu-border-color;
        min-width: 135px;

        &:last-child {
          width: 100%;
        }

        .group-separator {
          display: block;
          position: absolute;
          height: 2px;
          background: #fff;
          clear: both;
          left: 0;
          bottom: -1px;
          width: 47px;

          &.applied {
            width: 47px;
          }
        }
      }

      &:focus {
        a,
        .dd-link {
          text-decoration: none;
          background-color: $menu-hover-bgr;
        }
      }

      a,
      .dd-link {
        display: flex;
        align-items: center;
        padding: 6px 12px 6px;
        color: $text-color-dark-1;
        text-decoration: none;
        font-size: 1rem;
        white-space: nowrap;
        cursor: pointer;
        background-color: transparent;

        @include transition(background-color 0.2s linear);

        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $menu-hover-bgr;
        }
      }

      .folder-menu-item {
        @include flexbox();
        .sub-menu-icon {
          margin-left: -8px;
        }
        .folder-name {
          max-width: 252px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }

  &.open {
    .dd-menu-head {
      background-color: $menu-hover-bgr;
    }
    .dd-menu-hidden {
      display: block;
      z-index: 1001;
    }
    .dd-menu-second-level {
      top: 40px;
    }
  }

  &.open.disabled {
    .dd-menu-hidden {
      display: none !important;
    }
  }

  .menu-separator {
    border-top: 1px solid $separator-color;
    padding-top: 5px;
    margin-top: 5px;
  }
  .dd-link-header {
    display: block;
    padding: 5px 12px 5px 6px;
    line-height: 1.7rem;
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    white-space: nowrap;
    font-weight: 700;
    cursor: pointer;
    background: linear-gradient(225deg, #26ae4c 0%, #16974e 100%);
    border-radius: 4px;
    .sub-menu-icon {
      filter: brightness(0) invert(1);
    }
  }
  .dd-link-header-separate {
    margin: 0 5px;
    width: 1px;
    height: 35px;
    position: relative;
    right: -13px;
    background-color: #d8d8d8;
  }
}
app-share-link-page .dd-menu .dd-menu-head:before {
  content: none;
}
app-shared-folder-page .dd-menu .dd-menu-head:before {
  content: none;
}

.menu-separator {
  border-top: 1px solid $separator-color;
  padding-top: 5px;
  margin-top: 5px;
}

/* # Right Click Context Menu
================================================== */
.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  a {
    text-decoration: none;
  }

  .dd-menu {
    .dd-menu-hidden {
      position: relative;
      &.dd-menu-second-level {
        display: none;
        position: absolute;
        right: auto;
        left: 98%;
        top: -5px;
        &.open {
          display: block;
        }
        &.left {
          left: auto;
          right: 100%;
        }
      }
    }
  }
}

/* # Buttons
================================================== */
.button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  box-sizing: border-box;
  padding: 10px;
  height: 44px;
  line-height: 24px;
  position: relative;
  border: 0;
  outline: 0;

  @include transition(background-color 0.2s linear, color 0.2s linear);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  // *** Size Properties ***
  &.full-size {
    width: 100%;
  }

  &.medium-size {
    min-width: 120px;
  }

  &.small-size {
    min-width: 70px;
  }

  // *** Color properties ***
  &.col-blue {
    background-color: #2080fc;
    color: #fff;

    &:disabled:hover,
    &.disabled:hover,
    &.no-hover:hover {
      background-color: #2080fc;
    }

    &:hover {
      background-color: #1c6ed9;
    }

    &:active {
      background-color: #1366d2;
    }
  }

  &.col-light-blue {
    color: #2e7fff;
    background-color: #ddedff;
    &:hover {
      background-color: #b9daff;
    }
  }

  // *** Color properties ***
  &.col-white {
    background-color: #fff;
    color: #218ae6;

    &:disabled:hover,
    &.disabled:hover,
    &.no-hover:hover {
      background-color: #fff;
    }

    &:hover,
    &:active {
      background-color: #fff;
      color: #0860ab;
    }
  }

  &.col-green {
    background: linear-gradient(48deg, rgba(22, 151, 78, 1) 0%, rgba(38, 174, 76, 1) 40%);
    color: #fff;

    &:disabled:hover,
    &.disabled:hover,
    &.no-hover:hover {
      background-color: #fff;
    }

    &:hover,
    &:active {
      color: #fff;
      background: linear-gradient(48deg, rgba(16, 105, 55, 1) 0%, rgba(11, 139, 47, 1) 40%);
    }
  }

  &.col-none {
    background-color: transparent;
    color: #151515;

    &:disabled:hover,
    &.no-hover {
      background-color: transparent;
    }

    &:hover {
      background-color: #e2e5e8;
    }

    &:active {
      background-color: #dee1e4;
    }

    &.selected {
      background-color: transparent;
      color: $text-color-active;
    }
  }

  &.col-grey {
    background-color: #f2f3f6;
    color: #7a7a7a;

    &:disabled:hover {
      background-color: transparent;
    }

    &.no-hover:hover {
      background-color: #f2f3f6;
      color: #7a7a7a;
    }

    &:hover {
      background-color: #e2e5e8;
      color: #151515;
    }

    &:active {
      background-color: #dee1e4;
    }

    &.selected {
      background-color: transparent;
      color: $text-color-active;
    }
  }

  &.col-grey-skeleton {
    background-color: #ffffff;
    border: 2px solid #eaedef;
    color: #151515;

    &.button.big-size {
      line-height: 26px;
    }

    &:disabled:hover {
      background-color: transparent;
    }

    &.no-hover:hover {
      background-color: #f2f3f6;
      color: #7a7a7a;
    }

    &:hover {
      text-decoration: underline;
      border-color: #151515;
    }

    &:active {
      border-color: #151515;
      text-decoration: underline;
    }

    &.selected {
      text-decoration: underline;
    }
  }

  &.col-light-grey {
    background-color: #e7eaed;
    color: #151515;

    &:disabled:hover {
      background-color: transparent;
    }

    &.no-hover:hover {
      background-color: #f2f3f6;
      color: #7a7a7a;
    }

    &:hover {
      background-color: #e2e5e8;
    }

    &:active {
      background-color: #d8dadd;
    }

    &.selected {
      background-color: transparent;
      color: $text-color-active;
    }
  }

  &.col-menu {
    background-color: transparent;
    color: #151515;

    &:disabled:hover,
    &:disabled:active {
      background-color: transparent;
    }

    &:hover {
      background-color: $menu-hover-bgr;
    }

    &:active {
      background-color: $menu-active-bgr;
    }

    &.selected {
      background-color: $menu-hover-bgr;
      //color: $text-color-active;
    }
  }

  // *** Special Layouts ***
  &.with-icon {
    padding: 10px 10px 10px 58px;
    text-align: left;
    //text-indent: 12%;

    .icon-wrap {
      width: 30px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 30px;
        max-height: 24px;
        margin: auto;
        fill: #546065;
      }
    }
    &.selected {
      .icon-wrap {
        svg {
          fill: $text-color-active;
        }
      }
    }
  }
}

/* # Checkbox
================================================== */
.checkbox-item {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 24px;
  height: 29px;

  input[type='checkbox'] {
    position: absolute;
    left: -100px;
  }

  [type='checkbox']:checked + label {
    &:after {
      opacity: 1;
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

    &.checked-state {
      &:before {
        background-color: $checkbox-main-color;
      }
      &:after {
        opacity: 1;
      }
    }

    &.disabled {
      &:before {
        border: solid 1px $border-grey !important;
        background-color: $disabled-bgr !important;
        opacity: 0.6;
        cursor: default !important;
      }

      &:after {
        content: '' !important;
        opacity: 1 !important;
        background-image: none !important;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 3px;
      right: 3px;
      bottom: 5px;
      margin: auto;
      border-radius: 3px;
      border: solid 1px #bbb;
      background-color: $checkbox-sub-color;
      @include transition(border-color 0.2s linear);
      @include transition(opacity 0.1s linear);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 3px;
      right: 2px;
      bottom: 7px;
      margin: auto;
      opacity: 0;
      border-radius: 3px;
      @include transition(opacity 0.1s linear);
    }

    &:hover:before {
      border-color: $checkbox-main-color;
    }
  }

  &.style-multy-steps {
    label {
      &:after {
        content: '';
        opacity: 0;
      }

      &.view-1:after {
        content: '-';
        font-size: 30px;
        text-align: center;
        line-height: 15px;
        color: $checkbox-main-color;
        opacity: 1;
        top: 6px;
        right: 3px;
      }

      &.view-2 {
        &:before {
          background-color: $checkbox-main-color;
        }
        &:after {
          content: '';
          opacity: 1;

          background-image: url('../assets/images/checkmark-white.svg');
          background-repeat: no-repeat;
          background-clip: content-box;
          background-size: 28px;
          background-position: center;
        }
      }
    }
  }

  &.style-type-2 {
    [type='checkbox']:checked + label:before {
      background-color: $checkbox-main-color;
    }

    label {
      &:after {
        content: '';
        text-align: center;
        background-image: url('../assets/images/checkmark-white.svg');
        background-repeat: no-repeat;
        background-clip: content-box;
        background-size: 28px;
        background-position: center;
      }
    }
  }
}

/* # Inputs
================================================== */
.input-style {
  box-sizing: border-box;
  height: 46px;
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: $input-bgr;
  border: 1px solid $input-border-default;
  padding: 10px 15px;
  line-height: 26px;
  font-size: 1.2rem;

  @include transition(border-color 0.2s linear);

  &:disabled {
    opacity: 0.7;
    border-color: $input-border-disabled;
  }

  &:focus {
    border: 1px solid $input-border-focus;
  }

  &.input-error {
    border-color: $input-border-error;
  }

  &.textarea-style {
    height: 120px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    padding: 10px;
    white-space: pre-wrap;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    user-select: text;
  }
  &.invite {
    font-size: 14px;
  }
}

.input-with-button {
  .input-style {
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  .button {
    border-radius: 0 4px 4px 0;

    .menu-icon {
      margin: 0 auto;
    }
  }
}

/* # Errors
================================================== */
.form-error {
  color: $text-color-error;
}

/* # Skeleton Loader
================================================== */

// .skeleton-loader {
//   background: rgba(0, 0, 0, 0.03) !important;
//   @include border-radius(3px);
// }

/* # User Profile Icon
================================================== */
.user-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  color: #fff;
  background: #ff4b5e;
  box-shadow: 0 0 0 2px rgba(255, 75, 94, 0.25);
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

/* # Hide resources
================================================== */
.cp-load-resources {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

/* # RTL styles
================================================== */
.rtl {
  /* # Drop Down Menu
    ================================================== */
  .dd-menu {
    direction: rtl;
    text-align: right;
    .dd-menu-head {
      position: relative;
      padding: 5px 8px 5px 28px;
      &::before {
        right: auto;
        left: 10px;
      }
    }

    .dd-menu-hidden {
      left: auto;
      right: 0;

      > li {
        .folder-menu-item {
          .sub-menu-icon {
            margin-left: 0;
            margin-right: -8px;
          }
        }
      }
    }
    .dd-link-header {
      padding: 6px 6px 6px 12px;
    }

    .dd-link-header-separate {
      right: auto;
      left: -13px;
    }
  }

  /* # Buttons
    ================================================== */
  .button {
    &.with-icon {
      padding-left: 0;
      padding-right: 58px;
      text-align: right;
      //text-indent: 12%;

      .icon-wrap {
        right: 16px;
        left: auto;
      }
    }
  }

  /* # User Profile Icon
    ================================================== */
  .user-icon {
    margin-right: 0;
    margin-left: 5px;
  }
}

.recaptcha-disclaimer {
  font-size: 12px;
  margin-top: 12px;
  color: $text-color-grey;
  a {
    color: $text-color-dark-3;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

/* # Responsive Layout styles
================================================== */
@media only screen and (max-width: 1024px) {
  .dd-menu {
    .dd-menu-hidden {
      > li a,
      > li .dd-link {
        font-size: 1.2rem;
      }
    }
    .dd-link-header {
      padding: 0;
      line-height: 0;
      font-size: 0;
      background: white;
      .sub-menu-icon {
        filter: invert(56%) sepia(53%) saturate(4788%) hue-rotate(104deg) brightness(101%) contrast(70%);
      }
    }
  }

  .rtl {
    .button {
      &.with-icon {
        .icon-wrap {
          right: -10px;
          left: auto;
        }
      }
    }
    .dd-menu {
      .dd-link-header {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dd-menu {
    .dd-menu-hidden {
      > li a,
      > li .dd-link {
        padding: 5px;
        font-size: 1rem;
        line-height: 1.8rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .input-style {
    font-size: 1.4rem;
  }

  .user-icon {
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 420px) {
  .dd-menu {
    .dd-menu-hidden {
      > li a,
      > li .dd-link {
        padding: 4px 5px;
      }
    }
  }

  .button {
    height: 40px;
  }

  .input-style {
    font-size: 1.3rem;
    height: 42px;
  }
}
