// Body if modal is opened
body.dialog-open {
  overflow: hidden;

  ngx-smart-modal {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}

// Close button in modal
.nsm-dialog-btn-close {
  border: 0;
  background: none;
  color: #2d2d2d;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
  cursor: pointer;
  outline: 0;
  width: 25px;
  height: 25px;
  background: url('../assets/images/close.svg') no-repeat 0 0;
  background-size: 25px 25px;
  img,
  svg {
    display: none;
  }
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  transition: all $transition-duration;
  background-color: $color-overlay;
  z-index: 999;
  @include flexbox();
  @include align-items(center);

  &.nsm-overlay-close,
  &.transparent {
    background-color: transparent;
  }
}

// Dialog modal
.nsm-dialog {
  position: relative;
  // State of the dialog in the beginning (hidden)
  &:not(.nsm-dialog-open),
  &:not(.nsm-dialog-close) {
    opacity: 0;
    min-height: 0;
    width: 0;
    max-width: 0;
    visibility: hidden;
    overflow: hidden;
  }

  // When dialog is opening
  &.nsm-dialog-open {
    opacity: 1;
    visibility: visible;
    min-height: 150px;
    width: 90%;
    max-width: 520px;
    background-color: #fff;
    border-radius: 2px;
    right: 0;
    left: 0;
    margin: auto;
    padding: 40px 18px 18px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);

    &.view-change-language {
      max-width: 420px;
    }
  }

  // When dialog is closing
  &.nsm-dialog-close {
    opacity: 0;
  }
}

/* *************************
* Animations
* *************************/

// Fade
.nsm-dialog-animation-fade {
  transition: opacity $transition-duration;
}

// Left to right (ltr)
.nsm-dialog-animation-ltr {
  left: -50%;
  transition: left $transition-duration $transition-timing-function, opacity $transition-duration;

  &.nsm-dialog-open {
    left: 0;
  }

  &.nsm-dialog-close {
    left: -50%;
  }
}

// Right to left (ltr)
.nsm-dialog-animation-rtl {
  right: -50%;
  transition: right $transition-duration $transition-timing-function, opacity $transition-duration;

  &.nsm-dialog-open {
    right: 0;
  }

  &.nsm-dialog-close {
    right: -50%;
  }
}

// Top to bottom (ttb)
.nsm-dialog-animation-ttb {
  top: -50%;
  transition: top $transition-duration $transition-timing-function, opacity $transition-duration;

  &.nsm-dialog-open {
    top: $dialog-position-top;
  }

  &.nsm-dialog-close {
    top: -50%;
  }
}

// Bottom to top (btt)
.nsm-dialog-animation-btt {
  top: 100%;
  transition: top $transition-duration $transition-timing-function, opacity $transition-duration;

  &.nsm-dialog-open {
    top: $dialog-position-top;
  }

  &.nsm-dialog-close {
    top: 100%;
  }
}

.mobile-device {
  .overlay {
    padding: 2rem 0;
  }

  body.dialog-open {
    ngx-smart-modal {
      @include flexbox();
      @include align-items(center);
      position: fixed;
      z-index: 1100;
    }
  }

  .nsm-dialog.nsm-dialog-open {
    position: relative;
    left: auto;
    right: auto;
  }

  .overlay {
    overflow: auto;
  }
}

.rtl {
  .nsm-dialog-btn-close {
    right: auto;
    left: 10px;
  }
}

@media only screen and (max-height: 420px) and (orientation: landscape) {
  .overlay {
    padding: 1rem 0;
  }
  .nsm-dialog.nsm-dialog-open {
    padding: 30px 15px 15px;
  }

  .mobile-device {
    body.dialog-open {
      ngx-smart-modal {
        max-height: 100%;
      }
    }
  }
}

@media only screen and (max-height: 360px) and (orientation: landscape) {
  .nsm-dialog.nsm-dialog-open {
    max-height: initial;
  }
}

@media only screen and (max-width: 420px) {
  .nsm-dialog.nsm-dialog-open {
    padding: 25px 15px 15px;
    margin: auto;
    width: 85%;
  }

  .nsm-dialog-btn-close {
    top: 4px;
    right: 4px;
  }
}
