@charset "utf-8";
// Import Fonts
@import 'scss/fonts';

// Config Imports
@import 'scss/_variables';
@import 'scss/_mixins';
@import 'scss/_reset';
@import 'scss/breakpoints';
@import 'scss/utils';
@import 'scss/images';

// Base Styles Imports
@import 'scss/profile-menu.component';
@import 'scss/upload-button.component';
@import 'scss/image-page-box';
// Static Pages
// TODO move this to the static pages components
@import 'scss/public-page';

// Modal Styles
@import 'scss/ngx-smart-modal';
@import 'scss/ngx-perfect-scroll';

// Contextmenu module
@import '@angular/cdk/overlay-prebuilt.css';

/* #Base Styles
================================================== */
html {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  direction: ltr;

  &.rtl {
    direction: rtl;
  }

  &.open-mob-menu {
    body {
      height: 100%;
      min-height: initial;
    }
  }
}

body {
  font-size: inherit;
  background: $body-bgr;
  overflow-y: hidden;
  position: relative;
  // height: 100vh;
  width: 100%;

  @include transition(opacity 0.1s linear);

  &.show-drive {
    &:after,
    &:before {
      display: none;
      animation: none;
    }
    opacity: 1;
  }

  &.hide-drive-force {
    &:after {
      display: block !important;
    }
  }

  .cdk-overlay-container {
    z-index: 1050;
  }
}

app-root {
  display: block;
  overflow-y: auto;
}

.main-wrapper {
  height: 100vh;
  @include flexbox();
  @include flex-direction(column);
}

.disable-item {
  touch-action: none;
  pointer-events: none;
  opacity: 0.5;
}

#debuggerEL {
  display: none;
  position: fixed;
  width: 200px;
  max-height: 300px;
  overflow: auto;
  background: black;
  color: #fff;
  top: 20px;
  left: 0;
}

/* #Upload inputs
================================================== */
.upload-inputs {
  height: 0px;
  width: 0px;
  overflow: hidden;
  position: fixed;
  top: -1px;
  left: -1px;
}

/* #Content
================================================== */
.content {
  @include flex-grow(1);
  @include flexbox();
  @include flex-direction(column);

  max-width: 100%;
}
.mobile-device .content {
  @include flex-direction(row);
}

.page-wrapper {
  @include flex-grow(1);
  box-sizing: border-box;
  padding: 35px 0 5px 3rem;
  position: relative;
  overflow: hidden;

  &.public {
    padding: 0;
  }

  &.survey {
    overflow: auto;
  }

  .page-popup-container {
    position: absolute;
    color: #fff;
    width: 380px;
    z-index: 10;
    font-size: 1.2rem;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;

    .page-popup-wrap {
      display: inline-block;
      position: relative;
      text-align: initial;
      padding: 1.1rem 40px 1.1rem 1.1rem;
      @include border-radius(3px);
      @include box-shadow(0px 1px 24px 0 rgba(0, 0, 0, 0.2));

      &.page-error {
        padding: 1.1rem 40px 1.1rem 54px;
        &.disable-close {
          padding: 1.1rem 25px 1.1rem 54px;
        }
        background: #ff4d5e;
        .popup-error-list {
          list-style-type: none;
          margin: 0;
          > li {
            display: inline-block;
            clear: both;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        > .menu-icon {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 16px;
          top: 7px;

          margin: auto;
          vertical-align: top;
        }

        &.style404 {
          > .menu-icon,
          .button {
            top: 0;
            bottom: 0;
          }
        }
      }

      &.page-warning {
        padding: 1.4rem;
        background: #fff4e6;
        border: 1px solid #f8d4a6;
        color: #000;
        font-size: 1.2rem;
        line-height: 1.6rem;

        .dismiss-warning {
          cursor: pointer;
          text-decoration: underline;
          color: #216ce8;
          &:hover {
            text-decoration: none;
          }
        }
      }

      &.page-confirm {
        background: #1eb572;

        .button {
          top: 0;
          bottom: 0;
        }
      }
    }

    .button {
      position: absolute;
      right: 8px;
      top: 7px;

      margin: auto;
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }

  &.shlinkpage {
    padding-bottom: 0 !important;
  }
}

.not-logged {
  .sidebar {
    display: none;
  }

  .page-wrapper {
    &.shared-folder {
      padding: 35px 0 5px 0;
      .page {
        margin: 0 auto;
        max-width: 1400px;

        .ft-list.ft-body-list {
          padding-bottom: 105px;
        }
      }
    }
  }
}

.page {
  height: 100%;
  @include flexbox();
  @include flex-direction(column);
  font-size: 0;
  * {
    @include no-selection-highlight();
  }

  .page-header {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    @include no-selection-highlight();

    min-height: 46px;
    margin-bottom: 10px;
    padding-right: 2.8rem;

    .selected-item-menu {
      @include flexbox();
      @include justify-content(flex-end);
      @include flex(0 0 auto);
    }
  }

  .file-list {
    font-size: 1rem;
    @include flex-grow(1);
    position: relative;
  }
}

.change-language-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.4);
}

/* #Drag Styles
================================================== */
.drag-info {
  opacity: 0;

  &.active {
    opacity: 1;
    @include transition(opacity 0.3s linear);

    .counter {
      opacity: 1;
      @include transition(opacity 0.2s linear);
    }
  }

  display: inline-block;
  position: fixed;
  width: 30px;
  height: 30px;
  z-index: 1010;
  top: -60px;

  .counter {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 5px;
    min-width: 16px;
    height: 16px;
    padding: 1px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background: #126de2;
    z-index: 20;
    opacity: 0;
    @include border-radius(50%);
  }

  .menu-icon {
    position: absolute;
    height: 38px;
    top: -19px;
    left: -19px;
    z-index: 15;

    &.layer-1 {
      top: -16px;
      left: -16px;
      z-index: 14;
    }
    &.layer-2 {
      top: -13px;
      left: -13px;
      z-index: 13;
    }
  }
}

/* # Mobile Device Styles
================================================== */
.mobile-device {
  body {
    overflow: auto;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;

    &.no-scroll {
      overflow: hidden;
    }
  }

  &.os-iOS {
    body {
      * {
        touch-action: manipulation;
      }
    }
  }

  /* Styles when open popup on mobile device */
  &.mobile-modal-view {
    body {
      overflow: hidden;
      position: fixed;
      width: 100%;
      .content,
      .content > * {
        overflow: hidden;
        max-height: 100%;
      }

      .main-wrapper {
        max-height: 100vh;
        .page .file-list {
          height: 100%;
        }
      }
    }
  }

  .main-wrapper {
    box-sizing: border-box;
    height: auto;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100%;
  }

  .page-wrapper {
    padding: 30px 0 5px 3rem;

    .page-popup-container {
      position: fixed;
      box-sizing: border-box;
      top: 10px;
      z-index: 20;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 5px;
    }
  }

  .page {
    height: auto;
    min-height: 100%;
    .page-header {
      position: fixed;
      background: #fff;
      z-index: 5;
      top: 64px;
      padding-top: 30px;
      left: 25.75rem;
      right: 0;
      &.has-extra-menu {
        padding-top: 0;
        top: 127px;
      }
      &.has-exceed-banner {
        padding-top: 0;
        top: 177px;
        &.has-extra-menu {
          padding-top: 0;
          top: 237px;
        }
      }
    }
  }
  &.has-exceed-banner {
    padding-top: 130px;
  }
}

/* # RTL Styles
================================================== */
.rtl {
  .page-wrapper {
    padding: 35px 3rem 5px 0;
    &.public {
      padding: 0;
    }
    .page-popup-wrap {
      &.page-error {
        padding: 1.1rem 54px 1.1rem 40px;
        &.disable-close {
          padding: 1.1rem 54px 1.1rem 25px;
        }
        > .menu-icon {
          left: auto;
          right: 16px;
          transform: scaleX(-1);
        }
        .button {
          right: auto;
          left: 8px;
        }
      }
    }
  }

  .mobile-device {
    .page-wrapper {
      padding: 30px 3rem 5px 0;
    }
  }

  .page {
    .page-header {
      padding-right: 0;
      padding-left: 2.8rem;
    }
  }
}
.invite-banner {
  padding: 11px 14px;
  background-color: #ccf6cd;
  border-radius: 3px;
  margin-top: -8px;
  margin-bottom: 5px;
  margin-right: 2.8rem;
  &.has-extra-menu {
    margin-top: -12px;
  }
  @media only screen and (max-width: 1024px) {
    margin-right: 2rem;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 13px;
    margin-right: 0;
    border-radius: 0;
  }
  &__link {
    font-size: 15px;
    color: #216ce8;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  &__text {
    font-size: 15px;
    .strong {
      font-weight: 700;
    }
  }
}
.rtl .invite-banner {
  direction: rtl;
  margin-left: 2.8rem;
  margin-right: 0;
  @media only screen and (max-width: 1024px) {
    margin-left: 2rem;
  }
  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
  &__link {
    direction: rtl;
  }
  &__text {
    direction: rtl;
  }
}

.mobile-device .invite-banner {
  position: fixed;
  z-index: 5;
  top: 175px;
  left: 25.75rem;
  right: 0;
  margin-top: 0;
  @media only screen and (max-width: 1024px) {
    top: 95px;
    left: 2rem;
  }
  @media only screen and (max-width: 600px) {
    z-index: 4;
    top: 96px;
    left: 0;
  }
  &.has-extra-menu {
    @media only screen and (max-width: 1024px) {
      top: 138px;
    }
    @media only screen and (max-width: 600px) {
      top: 137px;
    }
  }
  &.has-exceed-banner {
    top: 285px;
    @media only screen and (max-width: 1024px) {
      top: 205px;
    }
    @media only screen and (max-width: 600px) {
      top: 207px;
    }
    &.has-extra-menu {
      @media only screen and (max-width: 1024px) {
        top: 248px;
      }
      @media only screen and (max-width: 600px) {
        top: 247px;
      }
    }
  }
}
.rtl.mobile-device .invite-banner {
  right: 25.75rem;
  left: 0;
  @media only screen and (max-width: 1024px) {
    right: 2rem;
  }
  @media only screen and (max-width: 600px) {
    right: 0;
  }
}
/* #Media Queries
================================================== */

@media only screen and (max-width: 1500px) {
  html {
    font-size: 13px;
  }

  .page-wrapper {
    padding: 30px 0 5px 3rem;
  }

  .page-header {
    padding-right: 1.7rem;
  }

  .rtl {
    .page-wrapper {
      padding: 30px 3rem 5px 0;
    }

    .page-header {
      padding-right: 0;
      padding-left: 1.7rem;
    }
  }
}

@media only screen and (max-width: 1320px) {
  html {
    font-size: 12px;
  }

  body {
    min-width: 1020px;
  }
  .not-logged {
    .page-wrapper {
      &.shared-folder {
        padding: 30px 0 5px 0;
        .page {
          max-width: 1020px;
        }
      }
    }
  }
}

// Specific Mobile Rules
@media only screen and (min-width: 1025px) {
  /* Mobile Device Only !!! */
  .mobile-device {
    body:not(.not-logged) .app-sidebar:not(.hide-element) {
      + .page-wrapper {
        padding-left: 25.75rem;
        padding-top: 0;
        .image-page-box.sh-link {
          padding-top: 1rem;
        }
      }
    }

    .main-wrapper {
      &:not(.static-page) {
        padding-top: 164px;
        &.has-extra-menu {
          padding-top: 229px;
        }
        &.has-invite-banner {
          padding-top: 225px;
          &.has-extra-menu {
            padding-top: 265px;
          }
        }
        &.has-exceed-banner {
          padding-top: 229px;
          &.has-extra-menu {
            padding-top: 339px;
          }
          &.has-invite-banner {
            padding-top: 335px;
            &.has-extra-menu {
              padding-top: 375px;
            }
          }
        }
      }
    }

    .page-wrapper {
      padding: 30px 0 5px 3rem;
    }

    .page {
      height: auto;
      min-height: 100%;
      .page-header {
        left: 25.75rem;
      }
    }
  }

  .rtl.mobile-device {
    body:not(.not-logged) .app-sidebar:not(.hide-element) {
      + .page-wrapper {
        padding-right: 25.75rem;
        padding-left: 0;
      }
    }

    .page-wrapper {
      padding: 30px 3rem 5px 0;
      padding-left: 0;
    }

    .page {
      .page-header {
        left: 0;
        right: 25.75rem;
      }
    }
  }
}

/* # Responsive Layout styles start at 1024
================================================== */
@media only screen and (max-width: 1024px) {
  html {
    font-size: 12px;
  }

  body {
    min-width: 320px;
  }

  .page-wrapper,
  .mobile-device .page-wrapper {
    padding: 20px 0 5px 2rem;
  }

  .not-logged {
    .page-wrapper,
    .mobile-device .page-wrapper {
      &.shared-folder {
        padding: 30px 0 5px 2rem;
      }
    }
  }

  .page {
    .page-header {
      padding-right: 2rem;
    }
  }

  /* Mobile Device Only !!! */
  .mobile-device {
    .main-wrapper {
      &:not(.static-page) {
        padding-top: 135px;
        &.has-extra-menu {
          padding-top: 174px;
        }
        &.has-invite-banner {
          padding-top: 170px;
          &.has-extra-menu {
            padding-top: 210px;
          }
        }
        &.has-exceed-banner {
          padding-top: 240px;
          &.has-extra-menu {
            padding-top: 284px;
          }
          &.has-invite-banner {
            padding-top: 280px;
            &.has-extra-menu {
              padding-top: 320px;
            }
          }
        }
      }
      &.file-version-page:not(.static-page),
      &.share-link-page:not(.static-page) {
        padding-top: 55px;
      }
    }

    .page {
      .page-header {
        padding-top: 18px;
        top: 40px;
        padding-left: 2rem;
        left: 0;
        &.has-extra-menu {
          padding-top: 0;
          top: 97px;
        }
        &.has-exceed-banner {
          padding-top: 0;
          top: 167px;
          &.has-extra-menu {
            padding-top: 0;
            top: 207px;
          }
        }
      }
    }
  }

  .rtl,
  .rtl.mobile-device {
    .page-wrapper {
      padding: 20px 2rem 5px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .page-wrapper {
    @include flexbox();
    @include flex-direction(column);
    padding: 20px 0 0 2rem;
  }

  .not-logged {
    .page-wrapper {
      &.shared-folder {
        padding: 20px 0 0 2rem;
        .page {
          margin: 0;
        }
      }
    }
  }

  .page {
    position: relative;
    @include flex-grow(1);
  }

  .rtl {
    .page-wrapper {
      padding: 20px 2rem 0 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .page-wrapper,
  .mobile-device .page-wrapper {
    padding: 0;
  }
  .mobile-device {
    .main-wrapper {
      &:not(.static-page) {
        &.has-extra-menu {
          padding-top: 180px;
        }
        &.has-invite-banner {
          padding-top: 194px;
          &.has-extra-menu {
            padding-top: 230px;
          }
        }
        &.has-exceed-banner {
          &.has-extra-menu {
            padding-top: 290px;
          }
          &.has-invite-banner {
            padding-top: 304px;
            &.has-extra-menu {
              padding-top: 340px;
            }
          }
        }
      }
    }
  }

  .not-logged {
    .page-wrapper,
    .mobile-device .page-wrapper {
      &.shared-folder {
        padding: 0;
      }
    }
  }

  .page {
    padding-left: 0;
    .page-header {
      padding-right: 1rem;
      padding-left: 1rem;
      min-height: 40px;
      margin-top: 10px;
      background: transparent;

      .selected-item-menu {
        overflow: visible;
        .mob-menu-wraper {
          .switch-view {
            padding: 0px 2px;
            border: 1px solid #a1a1a1;
            height: 34px;
            line-height: 30px;
            margin-left: 5px;
            display: inline-block;

            border: 1px solid transparent;
            &:active {
              background-color: transparent;
            }
          }

          .dd-menu {
            display: inline-block;
            margin-left: 5px;
          }
        }

        .dd-menu {
          > .button {
            padding: 2px 6px;
            border: 1px solid #a1a1a1;
            height: 40px;
            line-height: 30px;

            margin-left: 5px;
            &.switch-view {
              border: 1px solid transparent;
              &:active {
                background-color: transparent;
              }
            }

            .menu-icon {
              width: 26px;
            }
          }
          .dd-menu-hidden {
            left: auto;
            right: 40px;
            top: 0;

            > li.context-menu-item.applied:not(:last-child) .group-separator {
              width: 38px;
            }
          }
        }
      }
    }
  }

  .share-link-page .page {
    .page-header {
      .selected-item-menu {
        .mob-menu-wraper {
          .switch-view {
            display: none;
          }
        }
      }
    }
  }

  /* Mobile Device Only !!! */
  .mobile-device {
    .page {
      .page-header {
        left: 0;
        padding-left: 1rem;
        margin-top: 0;
      }
    }
  }

  .rtl,
  .rtl.mobile-device {
    .page-wrapper {
      padding: 0;
    }

    .page {
      .page-header {
        padding-right: 1rem;
        padding-left: 1rem;
        .selected-item-menu {
          .dd-menu {
            > .button {
              margin-left: 0;
              margin-right: 5px;
            }
            .dd-menu-hidden {
              left: 40px;
              right: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .page {
    .page-header {
      .selected-item-menu {
        .dd-menu {
          > .button {
            height: 34px;
            .menu-icon {
              width: 22px;
            }
          }
          .dd-menu-hidden {
            right: 36px;
          }
        }
      }
    }
  }

  /* Mobile Device Only !!! */
  .mobile-device {
    .page-wrapper {
      .page-popup-container {
        .page-popup-wrap {
          &.page-error {
            padding: 1.1rem 40px 1.1rem 40px;
            &.disable-close {
              padding: 1.1rem 25px 1.1rem 40px;
            }

            font-size: 1.1rem;
            > .menu-icon {
              top: 4px;
              left: 5px;
            }

            .button {
              top: 4px;
              right: 5px;
            }
          }
        }
      }
    }

    .page {
      .page-header {
        left: 0;
      }
    }
  }

  .rtl,
  .rtl.mobile-device {
    .page-wrapper {
      .page-popup-container {
        .page-popup-wrap {
          &.page-error {
            padding: 1.1rem 40px 1.1rem 40px;
            &.disable-close {
              padding: 1.1rem 40px 1.1rem 25px;
            }

            > .menu-icon {
              top: 4px;
              left: auto;
              right: 5px;
            }

            .button {
              top: 4px;
              right: auto;
              left: 5px;
            }
          }
        }
      }
    }

    .page {
      .page-header {
        .selected-item-menu {
          .dd-menu {
            .dd-menu-hidden {
              left: 36px;
              right: auto;
            }
          }
        }
      }
    }
  }
  .mobile-device {
    .main-wrapper {
      &:not(.static-page) {
        &.has-invite-banner.has-extra-menu {
          padding-top: 235px;
        }
        &.has-exceed-banner.has-invite-banner.has-extra-menu {
          padding-top: 345px;
        }
      }
    }
  }
}

@media only screen and (max-height: 360px) and (orientation: landscape) {
  .page {
    .page-header {
      .selected-item-menu {
        .dd-menu-hidden {
          max-height: 252px;
          overflow: auto;
        }
      }
    }
  }
}
app-wrapper-page {
  @include flexbox();
  @include flex-direction(row);
  max-width: 100%;
  width: 100%;
}
