@charset "utf-8";
// Import Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n9&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n7&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n8&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/c47696/00000000000000003b9b305e/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c47696/00000000000000003b9b305e/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c47696/00000000000000003b9b305e/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n1&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n6&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n4&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=i4&v=3')
      format('opentype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n3&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=b821e15f0e01274d6f67f8f15514f22175a78f48a3af15c0bd5954a2378b142b&fvd=n5&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 500;
}
