// General components
$header-bgr: #fdfdfd;
$body-bgr: #ffffff;
$sidebar-bgr: #f3f4f6;
$border-grey: #c7c7c7;
$disabled-bgr: #dcdcdc;
$separator-color: #dcdcdc;

$drop-highlight: #ebf4fe;

// Side bar
$progress-bar-bgr: #d7d5d5;
$progress-bar-safe-bgr: #126de2;
$progress-bar-critical-bgr: #ff4b5e;
$progress-bar-link-color: #126de2;

// Drop-down menus
$menu-bgr: #ffffff;
$menu-hover-bgr: #eef7ff; // #f9fcff;
$menu-selected-bgr: #ddedff; //#ebf4fe;
$menu-active-bgr: #e4eef8;
$menu-border-color: #e3e0e0;

//Checkbox
$checkbox-main-color: #126de2;
$checkbox-sub-color: #ffffff;

//Inputs
$input-border-default: #c7c7c7;
$input-border-focus: #126de2;
$input-border-disabled: #7d8589;
$input-border-error: #ff4b5e;
$input-bgr: #fdfdfd;

// Text Colors Schema
$text-color-black: #000000;
$text-color-dark-1: #151515;
$text-color-dark-3: #333333;
$text-color-grey: #7d8589;
$text-color-light-grey: #a1a1a1;
$text-color-muted-grey: #a6abae;
$text-color-white: #fdfdfd;
$text-color-active: #126de2; // #040405;
$text-color-error: #ff4b5e;
$text-color-android-banner: #ffffff;
$text-color-open-width: #7b858a;

// Ngx Smaert Modal
$color-overlay: rgba(0, 0, 0, 0.2);
$dialog-position-top: 20%;
$transition-duration: 400ms;
$transition-timing-function: linear;
$modal-separator-color: #dcdcdc;

$file-info-label-color: #757575;
