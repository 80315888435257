.rtl .page-wrapper.public {
  direction: rtl;
  .ps__rail-y {
    right: auto !important;
    left: 2px !important;
    .ps__thumb-y {
      right: auto;
      left: 0;
    }
  }
  > * {
    direction: rtl;
  }
}

.static-page-container {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  * {
    font-family: 'proxima-nova', sans-serif;
  }

  .main-container {
    position: relative;
    overflow: hidden;
    background-clip: content-box;
    height: 100%;
    margin-top: -1px;

    &.flex {
      @include flexbox();
      @include flex-direction(column);

      .inner-wrapper {
        flex-grow: 1;
      }
    }
  }

  .inner-wrapper {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .row-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.8rem;
    justify-content: space-between;
    font-size: 0;
    text-align: center;

    &.full {
      margin-left: -15px;
      margin-right: -15px;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  .a-title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 2.8rem;
    font-weight: 800;
    margin-bottom: 2rem;

    &.big {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    &.center {
      text-align: center;
    }

    color: #000;
  }

  .s-article {
    flex: 1;
    font-size: 1rem;

    > * {
      margin-bottom: 2.5rem;
    }

    .no-margin-btm {
      margin-bottom: 0;
    }

    .a-flex-content {
      @include flexbox();
      @include flex-direction(column);
      margin-bottom: 2rem;
      .ct-block {
        > * {
          padding-bottom: 1rem;
        }
      }
    }

    .white-box {
      @include border-radius(10px);
      @include box-shadow(0 0 10px rgba(0, 0, 0, 0.27));
      margin: 0 0 3.6rem;
      padding: 3px;
      position: relative;
      background: #fff;

      > * {
        z-index: 1;
        position: relative;
      }
    }

    .mob-order-reverse {
      @include flex-direction(column-reverse);
    }

    .a-img-wrap {
      img {
        max-width: 100%;
      }
    }

    .a-text {
      font-size: 1.25rem;
      line-height: 1.6rem;
      color: #000;
      margin-bottom: 2rem;

      &.center {
        text-align: center;
      }

      p {
        padding-bottom: 1.5rem;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .button {
      min-width: 150px;
    }

    .blue-bullet-list {
      list-style-type: none;
      text-align: left;
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-bottom: 15px;
      li {
        display: block;
        position: relative;
        padding: 0 0 0 24px;
        margin-bottom: 15px;

        &:last-child {
          padding-bottom: 0;
        }

        &:before,
        &:after {
          content: '';
          width: 18px;
          height: 18px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {
          @include border-radius(50%);
          background: #017ae9;
          z-index: 1;
        }
        &:after {
          z-index: 2;
          background-image: url('../assets/images/checkmark-white.svg');
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 26px;
        }

        &.declined {
          text-decoration: line-through;
          opacity: 0.4;
          &:before {
            background: #c3c3c3;
          }
          &:after {
            background-image: url('../assets/images/close.svg');
            background-size: 14px;
          }
        }
      }
    }

    .app-icons-list {
      list-style-type: none;
      @include flexbox();
      @include justify-content(center);
      li {
        display: inline-block;
        margin-right: 36px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .print-receipt {
      margin: 20px 0 30px;
      padding: 0 0 0 40px;
      background: url(../assets/images/public-page/print-icn.svg) 0 50% no-repeat;
      background-size: 24px auto;
      display: inline-block;
      clear: both;

      .open-print {
        color: #0092cc;
        text-decoration: underline;
        font-weight: 700;
        font-size: 1.3rem;
        &:hover {
          text-decoration: none;
        }
      }
    }

    &.activation-box {
      h5 {
        font-size: 1.4rem;
        font-weight: 400;
      }

      .activation-form {
        width: 280px;
        margin: auto auto 8rem;
        position: relative;
        @include flexbox();
        @include flex-direction(column);

        .input-group {
          @include flexbox();
          @include flex-direction(row);
          @include justify-content(space-between);
          @include align-items(center);
          padding-bottom: 25px;

          span {
            display: inline-block;
          }
          input {
            max-width: 120px;
            padding: 10px 10px;
            font-size: 1.4rem;
            height: 40px;
            text-transform: uppercase;
            text-align: center;
          }
        }

        .button:focus {
          background: #1c6ed9;
          @include box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.2));
        }
        .form-error {
          span {
            display: inline-block;
          }
          padding-top: 1rem;
          margin-bottom: -2.2rem;
          line-height: 1.2rem;
        }
      }

      .a-text {
        span,
        a {
          display: inline-block;
        }
        font-size: 1rem;
        a {
          color: #277bf1;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }

      .menu-icon {
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        vertical-align: middle;
        margin-top: -2px;

        &.help-icon {
          background-image: url('../assets/images/help-icon.svg');
        }
        &.form-error-icon {
          background-image: url('../assets/images/ic-activation-error.svg');
        }
      }
    }

    &.main-article {
      .a-title {
        &.big {
          font-size: 3rem;
          line-height: 3.6rem;
        }
      }

      .promo-oval {
        width: 160px;
        margin-top: -25px;
        margin-bottom: 5px;
        opacity: 1;
      }

      figure.ct-block {
        .promo-oval {
          width: 0;
          height: 0;
          opacity: 0;
        }
      }

      .app-links {
        padding-top: 10px;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }

    &.img-flip-pos {
      @include flexbox();
      @include flex-direction(column);
      .a-title {
        order: 1;
      }
      .a-text {
        order: 2;
      }
      figure {
        order: 3;
      }
    }

    &.with-border {
      border-bottom: 2px solid #dedede;
      padding-bottom: 2.5rem;
      margin-bottom: 0rem;
      figure {
        margin-bottom: 0;
        width: 100%;
        .ofs-img {
          width: 80%;
          margin: 0 auto;
          display: block;
          background: url('../assets/images/logos/MobiOffice-Primary.svg') 50% 50% no-repeat;
          background-size: contain;
          height: 12vh;
        }
      }
    }

    &.custom-margin {
      flex-direction: column;
      padding-top: 2rem;
      padding-bottom: 1.6rem;

      .a-text {
        font-size: 1.4rem;
      }

      > * {
        margin-bottom: 1.4rem;
      }
    }

    &.color-box {
      padding: 2.4rem 1.2rem;
      border-radius: 27px;
      box-shadow: 0 2 21px #000000;

      .a-title,
      .a-text {
        color: #fff;
      }

      .a-title {
        margin-bottom: 1rem;
        font-size: 2.8rem;
        line-height: 3rem;
        font-weight: 800;
      }

      .a-text {
        font-size: 1.3rem;
        font-weight: 700;
        max-width: 70%;
        margin: 0 auto;
      }

      figure {
        margin-bottom: 0;
      }

      .a-text a {
        color: #fff;
        text-decoration: underline;
      }
    }

    &.custom-green {
      background: rgb(96, 229, 202);
      background: url('../assets/images/svg-sprites/home-page-sprite.svg#upload-card-bg') 50% 50% no-repeat,
        linear-gradient(224deg, rgba(96, 229, 202, 1) 0%, rgba(13, 164, 131, 1) 55%, rgba(38, 191, 249, 1) 100%);
      background-size: cover;

      .a-title {
        text-shadow: 0 0px 5px #717171;
      }

      .upload-button {
        display: inline-block;
        background: url('../assets/images/svg-sprites/home-page-sprite.svg#upload-button') 50% 50% no-repeat;
        background-size: cover;
        width: 78px;
        height: 78px;
        @include transition(margin linear 0.2s);
      }

      .upl-btn-wrap {
        width: 78px;
        height: 78px;
        margin: auto;
        padding-top: 10px;
        &:hover {
          .upload-button {
            margin-top: -10px;
          }
        }
      }
    }

    &.ofs-more {
      padding: 125px 15px 0;
      margin-top: -170px;
      margin-bottom: 40px;
      text-align: center;
      background-color: #f8f8f8;

      figure {
        img {
          width: 50px;
          height: auto;
        }
      }

      .a-text {
        padding: 30px 10px 20px;
        text-align: center;
      }

      .learn-more-link a {
        display: inline-block;
        font-size: 1.4rem;
        line-height: 26px;
        color: #2e7fff;
        font-weight: 600;
        padding-right: 60px;
        padding-bottom: 40px;
        position: relative;

        span {
          text-decoration: underline;
        }
        &:hover {
          text-decoration: none;
          span {
            text-decoration: none;
          }
        }
        &:after {
          content: '';
          width: 38px;
          height: 38px;
          @include border-radius(50%);
          display: inline-block;
          position: absolute;
          right: 0;
          top: -6px;
          background: url('../assets/images/back-arrow-white.svg') #2e7fff no-repeat 50% 50%;
          @include transform(scaleX(-1));
          @include transition(all 0.2s linear);
        }
        &:hover:after {
          right: 5px;
        }
      }
    }

    &.testimonials {
      .white-box {
        text-align: center;
        .a-title {
          padding: 20px 0 20px;
          margin-bottom: 0;
          font-size: 1.8rem;
        }
        .a-text {
          padding: 0 10px;
        }
      }
    }

    &.order-error {
      padding: 4rem 0 2rem 0;
      margin-top: 2rem;
      a {
        text-decoration: underline;
      }
      background: #fafafa;
      box-shadow: 0 0 3px 1px #ccc;
    }

    &.home-image-article {
      .a-img-wrap {
        text-align: center;
      }
      .sprite-image {
        background-image: url('../assets/images/public-page/home/sprite-home.webp');
        background-repeat: no-repeat;
        background-size: 600px 300px;
        width: 300px;
        height: 300px;
        display: inline-block;
      }
      .mobile-device-section {
        background-position: -300px 0;
      }
      .synchronizer-section {
        background-position: 0 0;
      }
    }
  }

  .button.big-size {
    line-height: 30px;
    height: 50px;
    min-width: 200px;
    font-size: 18px;
    font-weight: 500;
  }

  .button.xbig-size {
    height: 60px;
    line-height: 40px;
    min-width: 220px;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 15px;
  }

  .mini-articles {
    .s-article {
      padding-bottom: 1.2rem;
      .a-img-wrap {
        margin-top: -12px;
        text-align: center;
        span {
          width: 160px;
          height: 160px;
          display: inline-block;
          background-image: url('../assets/images/public-page/home/sprite-mini-articles.webp');
          background-size: auto 160px;
          &.sprite-manage-protect {
            background-position: 0px -0px;
          }
          &.sprite-share-access {
            background-position: -160px -0px;
          }
          &.sprite-store-sync {
            background-position: -320px -0px;
          }
        }
      }
    }
  }
}

.pricing .main-container {
  display: flex;
  flex-direction: column;
  .inner-wrapper {
    flex-grow: 1;
  }
}

.mobile-device .static-page-container {
  position: relative;
}

.lgcode-bg,
.lgcode-ru {
  .static-page-container {
    .s-article {
      &.main-article {
        .a-title {
          &.big {
            font-size: 2.6rem;
            line-height: 3.2rem;
          }
        }
      }
    }
  }
}

.rtl {
  .static-page-container {
    .s-article {
      .blue-bullet-list {
        text-align: right;
        li {
          padding: 0 24px 0 0;
          &:before,
          &:after {
            left: auto;
            right: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .app-icons-list {
        li {
          margin-right: 0;
          margin-left: 36px;
          &:last-child {
            margin-left: 0;
          }
        }
      }

      .print-receipt {
        padding: 0 40px 0 0;
        background-position: right 0;
      }

      &.ofs-more {
        .learn-more-link {
          a {
            padding-right: 0px;
            padding-left: 60px;

            &:after {
              right: auto;
              left: 0;
              @include transform(scaleX(1));
            }
          }
        }
      }

      &.activation-box {
        .menu-icon {
          margin-right: 0;
          margin-left: 6px;
        }
      }
    }
  }
}

.submit-loader {
  display: none;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  &.show {
    display: block;
  }
}

.animation-block {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  transform: translate(-50%, -50%);

  &::after {
    content: '';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #c5c4c4;
    border-color: #c5c4c4 transparent #c5c4c4 transparent;
    animation: spinner 1.2s linear infinite;
  }
}

@media only screen and (min-width: 370px) {
  .pricing .static-page-container {
    .s-article {
      .white-box {
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
      }
    }
  }
}

@media only screen and (min-width: 440px) {
  .static-page-container {
    .s-article {
      .app-icons-list {
        li {
          img {
            width: 52px;
            height: 52px;
          }
        }
      }

      &.main-article {
        .promo-oval {
          width: 180px;
        }
      }

      &.home-image-article {
        .sprite-image {
          background-size: 760px 380px;
          width: 380px;
          height: 380px;
        }
        .mobile-device-section {
          background-position: -380px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 740px) {
  .static-page-container {
    .inner-wrapper {
      padding: 35px 20px 0;
    }
    .row-section {
      text-align: left;
      flex-direction: row;
      margin-bottom: 4rem;

      &.full {
        margin-left: -20px;
        margin-right: -20px;
      }

      &.mb-extra {
        margin-bottom: 6rem;
      }
    }

    .a-title {
      font-size: 2.2rem;
      line-height: 2rem;

      &.big {
        font-size: 2.6rem;
        line-height: 3rem;
        margin-bottom: 3rem;
      }
    }

    .s-article {
      padding: 0 2rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      > * {
        margin-bottom: 1.6rem;
      }

      .a-text {
        font-size: 1.15rem;
      }

      .a-flex-content {
        @include flex-direction(row);
        @include justify-content(space-between);

        @include flex-wrap(wrap); // Check public page

        margin-bottom: 0;
        > figure {
          max-width: 50%;
          min-width: 50%;
        }

        > div {
          max-width: 45%;
        }

        .align-vertically {
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(center);
        }
      }

      .self-center {
        @include align-self(center);
        img {
          width: 100%;
          height: auto;
        }
      }

      .mob-order-reverse {
        @include flex-direction(row);
      }

      .store-btn-wrap {
        margin: 0;
      }

      .no-margin-bottom {
        margin-bottom: 0;
      }

      &.main-article {
        .a-title {
          padding-top: 2.5rem;
        }

        .ct-block {
          position: relative;
          .promo-oval {
            width: 0;
            height: 0;
            opacity: 0;
          }
        }

        figure.ct-block {
          .promo-oval {
            opacity: 1;
            width: 12vw;
            height: auto;
            max-width: 110px;
            display: block;
            margin-bottom: -15%;
            margin-left: 10%;
            margin-right: auto;
            z-index: 1;
            position: relative;
          }
        }

        .app-links {
          display: block;
        }
      }

      &.img-flip-pos {
        figure {
          order: 1;
        }
        .a-title {
          order: 2;
        }
        .a-text {
          order: 3;
        }
      }

      &.center-mode {
        text-align: center;
      }

      &.with-border {
        border-bottom: 0;
        border-right: 2px solid #dedede;
        padding-bottom: 0;
        padding: 0 2rem;

        figure {
          text-align: right;
        }
      }

      &.flex {
        @include flexbox();
      }

      &.custom-margin {
        > * {
          margin-bottom: 1rem;
        }

        .a-title {
          line-height: 1;
        }

        .a-block {
          padding-top: 10px;
        }
      }

      &.ofs-more {
        margin-top: -180px;
        padding-top: 155px;
        .a-text {
          padding: 30px 45px 20px;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.testimonials {
        .white-box {
          max-width: 600px;
          .a-title {
            padding: 30px 0 30px;
          }
        }
      }
    }
  }

  .rtl .static-page-container {
    .a-title {
      text-align: right;
      &.center {
        text-align: center;
      }
    }

    .s-article {
      padding: 0 2rem;
      .a-text {
        text-align: right;
        &.center {
          text-align: center;
        }
      }

      &:first-child {
        padding-right: 0;
      }
      &:last-child {
        padding-left: 0;
      }

      &.main-article {
        text-align: right;
      }

      &.color-box {
        padding: 2.4rem 1.2rem;
      }

      &.with-border {
        border-left: 2px solid #dedede;
        border-right: 0;
        figure {
          text-align: left;
        }
      }

      &.custom-margin {
        text-align: right;
      }

      &.ofs-more {
        padding-top: 155px;

        .a-text {
          text-align: center;
        }
      }

      &.testimonials {
        .feedback-box {
          .a-title,
          .a-text {
            text-align: center;
          }
        }
      }
    }
  }

  .lgcode-bg,
  .lgcode-ru {
    .static-page-container {
      .s-article {
        &.main-article {
          .a-title {
            &.big {
              font-size: 2.8rem;
              line-height: 3.4rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .static-page-container {
    .s-article {
      &.home-image-article {
        .sprite-image {
          background-size: 860px 430px;
          width: 430px;
          height: 430px;
        }
        .mobile-device-section {
          background-position: -430px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .static-page-container {
    .inner-wrapper {
      padding: 0 50px;
      max-width: 1300px;
      width: 100%;
      margin: auto;

      &.no-max-width {
        max-width: none;
        margin: 0;
      }
    }

    .limit-width {
      max-width: 1300px;
      margin: auto;
    }

    .row-section {
      margin-bottom: 7rem;
      &:first-of-type {
        padding-top: 2.5rem;
      }

      &.full {
        margin-left: -50px;
        margin-right: -50px;
      }
    }

    .a-title.big {
      font-size: 4.2rem;
      line-height: 4.8rem;
      margin-bottom: 4rem;
    }

    .s-article {
      padding: 0 4rem;

      .a-text,
      .blue-bullet-list {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      .a-text.big {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      .a-flex-content {
        > figure {
          min-width: 55%;
        }
        > div {
          max-width: 40%;
        }
      }

      .app-icons-list {
        li img {
          width: 62px;
          height: 62px;
        }
      }

      &.main-article {
        .a-title {
          margin-right: -2rem;
          &.big {
            font-size: 4.2rem;
            line-height: 4.5rem;
          }
        }

        .button {
          margin-top: 1rem;
        }

        figure.ct-block {
          .promo-oval {
            width: 14vw;
            max-width: 125px;
            margin-bottom: -15%;
            margin-left: 12%;
          }
        }
      }

      &.img-flip-pos {
        .a-img-wrap {
          text-align: left;
        }
      }

      &.custom-width {
        .a-title,
        .a-text {
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
        }

        .a-text {
          max-width: 840px;
        }
      }

      &.with-border {
        figure img {
          display: flex;
          margin: auto;
        }
      }

      &.color-box {
        .a-text {
          max-width: 55%;
        }
      }

      &.plans-article {
        .a-title.big {
          font-size: 3.4rem;
          line-height: 4.2rem;
          margin-bottom: 5rem;
          margin-top: -3.4rem;
        }
      }

      &.ofs-more {
        margin-top: -200px;
        padding-top: 150px;
        padding-bottom: 20px;
      }

      &.testimonials {
        padding-bottom: 10rem;
        .a-title {
          margin-bottom: 6rem;
        }
        background: url('../assets/images/svg-sprites/pricing-page-sprite.svg#testimonials-section-bgr') no-repeat;
        background-size: 90%;
        background-position: center;
      }

      &.home-image-article {
        .sprite-image {
          background-size: 1012px 506px;
          width: 506px;
          height: 506px;
        }
        .mobile-device-section {
          background-position: -506px 0;
        }
      }
    }
  }

  .pricing .static-page-container {
    .s-article {
      .white-box {
        margin-left: 0;
        margin-right: 0;
        max-width: 31.4%;
        padding: 3px;
      }
    }
  }

  .rtl .static-page-container {
    .s-article {
      padding: 0 4rem;

      &.img-flip-pos {
        .a-img-wrap {
          text-align: right;
        }
      }

      &.main-article {
        .a-title {
          margin-right: 0;
        }
      }
    }
  }

  .lgcode-bg,
  .lgcode-ru {
    .static-page-container {
      .s-article {
        &.main-article {
          .a-title {
            &.big {
              font-size: 3.6rem;
              line-height: 4.2rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .page-wrapper.public,
  .mobile-device .page-wrapper.public,
  .rtl .page-wrapper.public,
  .rtl.mobile-device .page-wrapper.public {
    padding: 0;
  }
}

@media only screen and (min-width: 1120px) {
  .static-page-container {
    .s-article {
      &.home-image-article {
        .sprite-image {
          background-size: 1124px 562px;
          width: 562px;
          height: 562px;
        }
        .mobile-device-section {
          background-position: -562px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .static-page-container {
    .s-article {
      &.home-image-article {
        .sprite-image {
          background-size: 1296px 648px;
          width: 648px;
          height: 648px;
        }
        .mobile-device-section {
          background-position: -648px 0;
        }
      }

      &.testimonials {
        background-size: 95%;
      }
      &.main-article {
        figure.ct-block {
          .promo-oval {
            width: 14vw;
            max-width: 135px;
            margin-bottom: -14%;
            margin-left: 13%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .static-page-container {
    .row-section {
      margin-bottom: 8rem;
    }

    .a-title {
      font-size: 2.4rem;
    }

    .s-article {
      padding: 0 6rem;

      .blue-bullet-list {
        font-size: 1.1rem;
        li {
          padding: 0 0 0 36px;
          margin-bottom: 14px;
          &:after,
          &:before {
            top: 2px;
            width: 20px;
            height: 20px;
            left: 0;
          }
        }
      }

      .store-btn-wrap {
        padding-top: 2rem;
      }

      &.with-border {
        figure img {
          max-width: 462px;
        }
      }

      &.plans-article {
        .a-title.big {
          margin-top: -2.6rem;
          font-size: 3.2rem;
          line-height: 4rem;
          margin-bottom: 4.6rem;
        }
      }

      &.ofs-more {
        margin-top: -240px;
        padding-top: 180px;

        figure img {
          width: 75px;
        }
      }

      &.testimonials {
        background-size: 100%;
        .a-title.big {
          margin-bottom: 120px;
          margin-top: -20px;
        }
      }

      &.activation-box {
        .activation-form {
          .form-error {
            font-size: 0.9rem;
          }
        }
      }

      &.home-image-article {
        .sprite-image {
          background-size: 1320px 660px;
          width: 660px;
          height: 660px;
        }
        .mobile-device-section {
          background-position: -660px 0;
        }
      }
    }
  }

  .rtl .static-page-container {
    .s-article {
      padding: 0 6rem;

      .blue-bullet-list {
        li {
          padding: 0 36px 0 0;
          &:after,
          &:before {
            left: auto;
            right: 0;
          }
        }
      }

      &.main-article {
        > * {
          margin-right: auto;
          margin-left: 7rem;
        }
      }
    }
  }

  .lgcode-bg,
  .lgcode-ru {
    .static-page-container {
      .s-article {
        &.main-article {
          .a-title {
            &.big {
              font-size: 4rem;
              line-height: 4.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .page-wrapper.public,
  .mobile-device .page-wrapper.public,
  .rtl .page-wrapper.public,
  .rtl.mobile-device .page-wrapper.public {
    padding: 0;
  }
}
