.profile-menu {
  .user-name {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    &.default {
      color: $text-color-dark-3;
      font-size: 1.1rem;
      margin-left: 3px;
    }
  }
}

/* # RTL styles
================================================== */
.rtl {
  .profile-menu {
    .user-icon {
      margin-right: 0;
      margin-left: 5px;
    }
  }
  .user-name.default {
    margin-left: 0;
    margin-right: 3px;
  }
}

@media only screen and (max-width: 1024px) {
  .profile-menu {
    &.static {
      .user-name {
        display: none;
      }
      .dd-menu .dd-menu-head {
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}
