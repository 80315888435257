.upload-button {
  margin-bottom: 40px;
  font-size: 0;

  * {
    cursor: pointer;
  }

  &:focus {
    box-sizing: border-box;
    outline: solid 1px #126de2;
    border: 0;
    border-radius: 4px;
  }

  span {
    vertical-align: middle;
  }

  &.mobile-view {
    margin-bottom: 0;

    .button {
      padding: 0 11px 0 2px;
      font-size: 14px;

      &.with-icon {
        text-indent: 0;
      }

      .icon-wrap {
        position: relative;
        width: 30px;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        top: auto;
        left: auto;
        bottom: auto;

        svg {
          max-width: 20px;
          max-height: 20px;
        }
      }
    }

    .dd-menu-hidden {
      left: initial;
      right: 0;
    }
  }

  .dd-menu-hidden {
    left: 0;
    right: 0;
    top: 0;

    .separator {
      width: 100%;
      height: 1px;
      background-color: $menu-border-color;
      margin: 10px 0;
    }

    &.upload-button-list {
      top: 47px;
    }
  }

  .button {
    position: relative;
    height: 40px;
    line-height: 22px;
    &.upload-button-main {
      background-color: #2080fc;
      font-size: 16px;
    }
    .upload-button-plus {
      font-size: 25px;
      font-weight: 400;
      position: relative;
      top: 8px;
    }
  }

  input[type='file'] {
    position: absolute;
    top: -80px;
    opacity: 0;
    width: 0;
  }
}

.rtl .upload-button {
  &.mobile-view {
    .dd-menu-hidden {
      left: 0;
      right: initial;
    }
  }
}

// For Small Screens
@media only screen and (max-width: 1320px) {
  .upload-button {
    margin-bottom: 25px;
  }
}
