/* # Site Icons
================================================== */
.menu-icon,
.sub-menu-icon {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 100%;
  background-position: center center;
  background-size: contain;
}

.nein.menu-icon {
  display: none !important;
}

.sub-menu-icon {
  height: 20px;
  margin-right: 4px;
}

[class*='iconame-'] {
  background-repeat: no-repeat;
}

.iconame-mi-documents {
  background-image: url('../assets/images/logos/MobiDocs-Primary-icon.svg');
}

.iconame-mi-sheets {
  background-image: url('../assets/images/file-icons/mi-sheets.svg');
}

.iconame-mi-slides {
  background-image: url('../assets/images/file-icons/mi-slides.svg');
}

.iconame-presentation-color {
  background-image: url('../assets/images/file-icons/pptx.svg');
}

.iconame-sheet-color {
  background-image: url('../assets/images/file-icons/xlsx.svg');
}

.iconame-doc-color {
  background-image: url('../assets/images/file-icons/docx.svg');
}

.iconame-officesuite-color {
  background-image: url('../assets/images/logos/MobiOffice-Primary-icon.svg');
}

.iconame-pdf-extra-color {
  background-image: url('../assets/images/logos/MobiPDF-Primary-icon.svg');
}

.iconame-pdf-logo-color {
  background-image: url('../assets/images/logos/MobiPDF-Primary-icon.svg');
}

.iconame-mobidrive-color {
  background-image: url('../assets/images/logos/MobiDrive-Icon.svg');
}

.iconame-hamburger-menu {
  background-image: url('../assets/images/hamburger-menu.svg');
}

.iconame-drive-dark-logo {
  background-image: url('../assets/images/drive-dark-logo.svg');
}

.iconame-backups {
  background-image: url('../assets/images/backups-page-icon.svg');
}

.iconame-drive {
  background-image: url('../assets/images/drive-icon.svg');
}

.iconame-links {
  background-image: url('../assets/images/links-icon.svg');
}

.iconame-shared-with-me {
  background-image: url('../assets/images/profile-menu/ic-shared-with-me.svg');
}
.iconame-shared-by-me {
  background-image: url('../assets/images/profile-menu/ic-shared-by-me.svg');
}

.iconame-account-setting {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#account-setting');
}

.iconame-product-updates {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#product-updates');
}

.iconame-upgrade-storage {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#upgrade-storage');
}

.iconame-activate {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#ic-activate-key');
}

.iconame-sign-out {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#sign-out');
}

.iconame-help {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#help');
}

.iconame-langs {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#language-icon');
}

.iconame-win-dw {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#ic-windows-pc');
}

.iconame-officesuite {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#os-now-icon-new');
}

.iconame-pdf-extra {
  background-image: url('../assets/images/logos/MobiPDF-Secondary-Grayscale-icon.svg');
}

.iconame-mobisystems {
  background-image: url('../assets/images/svg-sprites/profile-menu-sprite.svg#mobisystems-icon');
}
.iconame-back-arrow {
  background-image: url('../assets/images/back-arrow.svg');
}
.iconame-back-arrow-white {
  background-image: url('../assets/images/back-arrow-white.svg');
}
.iconame-upload-file {
  background-image: url('../assets/images/svg-sprites/action-icons-sprite.svg#upload-file');
}

.iconame-upload-folder {
  background-image: url('../assets/images/svg-sprites/action-icons-sprite.svg#upload-folder');
}

.iconame-delete {
  background-image: url('../assets/images/action-icons/deleted-files.svg');
}

.iconame-remove {
  background-image: url('../assets/images/action-icons/remove-from-recent.svg');
}

.iconame-restore {
  background-image: url('../assets/images/action-icons/restore-bin.svg');
}

.iconame-download-file {
  background-image: url('../assets/images/action-icons/download-file-new.svg');
}

.iconame-download-file-white {
  background-image: url('../assets/images/download-file-white.svg');
}

.iconame-new-folder {
  background-image: url('../assets/images/svg-sprites/action-icons-sprite.svg#new-folder');
}

.iconame-rename {
  background-image: url('../assets/images/action-icons/rename.svg');
}

.iconame-move {
  background-image: url('../assets/images/action-icons/move.svg');
}

.iconame-copy {
  background-image: url('../assets/images/action-icons/copy.svg');
}

.iconame-file-properties {
  background-image: url('../assets/images/action-icons/file-properties.svg');
}

.iconame-version-history {
  background-image: url('../assets/images/action-icons/version-history.svg');
}

.iconame-active-shared-file {
  background-image: url('../assets/images/active-shared-file2-01.svg');
}

.iconame-disabled-shared-file {
  background-image: url('../assets/images/disabled-shared-file2-01.svg');
}

.iconame-open-with {
  background-image: url('../assets/images/ic_open_with.svg');
}

.iconame-share-file {
  background-image: url('../assets/images/action-icons/share-file-new.svg');
}

.iconame-share-file-ind {
  background-image: url('../assets/images/share-file-indicator.svg');
}

.iconame-stop-sharing {
  background-image: url('../assets/images/action-icons/stop-sharing-new.svg');
}

.iconame-share-file-white,
.iconame-share-file.list:hover {
  background-image: url('../assets/images/share-file-white.svg');
}

.iconame-copy-link {
  background-image: url('../assets/images/copy-link.svg');
}

.iconame-email {
  background-image: url('../assets/images/send-email-white.svg');
}

.iconame-favorites {
  background-image: url('../assets/images/favorites-section.svg');
}

.iconame-favorites-full {
  background-image: url('../assets/images/favorites-section-full.svg');
}

.iconame-arrow-forward {
  background-image: url('../assets/images/arrow-forward.svg');
}

.iconame-arrow-backward {
  background-image: url('../assets/images/arrow-backward.svg');
}

.iconname-arrow-dark-down {
  background-image: url('../assets/images/arrow-dark-down.svg');
}

.iconame-arrow-down-white {
  background-image: url('../assets/images/arrow-down-white.svg');
}

.iconame-search-white {
  background-image: url('../assets/images/iconame-search.svg');
}

.iconame-search-white-btn {
  background-image: url('../assets/images/search-button.svg');
}

.search-button-dark {
  background-image: url('../assets/images/search-button-dark.svg');
}

.iconame-checkmark {
  background-image: url('../assets/images/checkmark.svg');
}

.iconame-checkmark-white {
  background-image: url('../assets/images/done-white-button-01.svg');
}

.iconname-chevron-right {
  background-image: url('../assets/images/ic_chevron_right.svg');
}

.iconame-close {
  background-image: url('../assets/images/close.svg');
}

.iconame-close-white {
  background-image: url('../assets/images/close-white.svg');
}

.iconame-done {
  background-image: url('../assets/images/done.svg');
}

.iconame-retry {
  background-image: url('../assets/images/retry.svg');
}

.iconame-loading {
  background-image: url('../assets/images/LoaderXport.gif?1');
}

.iconame-page-error {
  background-image: url('../assets/images/error-ghost.svg');
}

.iconame-no-internet {
  background-image: url('../assets/images/no-connection-white.svg');
}

.iconame-file-error {
  background-image: url('../assets/images/error-file-upl.svg');
}

.iconame-red-warning {
  background-image: url('../assets/images/red-warning.svg');
}

.iconame-grid-view {
  background-image: url('../assets/images/action-icons/list-view.svg');
}

.iconame-list-view {
  background-image: url('../assets/images/svg-sprites/action-icons-sprite.svg#grid-view');
}

.iconame-print-white {
  background-image: url('../assets/images/print-white-01.svg');
}

.iconame-folder-breadcrumb {
  background-image: url('../assets/images/folder-breadcrumb.svg');
}

.iconame-folder-breadcrumb-blue {
  background-image: url('../assets/images/folder-breadcrumb-blue.svg');
}

.iconame-action-menu-dropdown {
  background-image: url('../assets/images/action-menu-dropdown.svg');
}

.iconame-action-menu-dropdown-white {
  background-image: url('../assets/images/more-dropdown-white.svg');
}

.iconame-navigate-prev {
  background-image: url('../assets/images/navigate-prev-01.svg');
}

.iconame-navigate-next {
  background-image: url('../assets/images/navigate-next-01.svg');
}

.iconame-zoom-in {
  background-image: url('../assets/images/plus-01.svg');
}

.iconame-zoom-out {
  background-image: url('../assets/images/minus-01.svg');
}

.iconame-zoom-reset {
  background-image: url('../assets/images/zoom-reset-01.svg');
}

.iconame-fullscreen-out {
  background-image: url('../assets/images/exit-fullscreen-01.svg');
}

.iconame-fullscreen-in {
  background-image: url('../assets/images/fullscreen2-01.svg');
}

.iconame-no-preview-found {
  background-image: url('../assets/images/preview-not-loaded-01.svg');
}

.iconame-rotate-ccw {
  background-image: url('../assets/images/rotate-ccw-01.svg');
}

.iconame-rotate-cw {
  background-image: url('../assets/images/rotate-cw-01.svg');
}

/* # File Type Icons
================================================== */
[class*='iconame-ft'] {
  background-size: 100% auto;
  background-image: url('../assets/images/file-icons/unknown.svg');
}

.iconame-ft-folder {
  background-image: url('../assets/images/file-icons/folder-yellow.svg');
}

.iconame-ft-folder-error {
  background-image: url('../assets/images/folder-upload-error.svg');
}

.iconame-ft-docx {
  background-image: url('../assets/images/file-icons/docx.svg');
}

.iconame-ft-doc {
  background-image: url('../assets/images/file-icons/doc.svg');
}

.iconame-ft-docm {
  background-image: url('../assets/images/file-icons/docm.svg');
}

.iconame-ft-dot {
  background-image: url('../assets/images/file-icons/dot.svg');
}

.iconame-ft-dotm {
  background-image: url('../assets/images/file-icons/dotm.svg');
}

.iconame-ft-dotx {
  background-image: url('../assets/images/file-icons/dotx.svg');
}

.iconame-ft-csv {
  background-image: url('../assets/images/file-icons/csv.svg');
}

.iconame-ft-xlsx {
  background-image: url('../assets/images/file-icons/xlsx.svg');
}

.iconame-ft-xlsm {
  background-image: url('../assets/images/file-icons/xlsm.svg');
}

.iconame-ft-xls {
  background-image: url('../assets/images/file-icons/xls.svg');
}

.iconame-ft-xltx {
  background-image: url('../assets/images/file-icons/xltx.svg');
}

.iconame-ft-xlt {
  background-image: url('../assets/images/file-icons/xlt.svg');
}

.iconame-ft-pdf {
  background-image: url('../assets/images/file-icons/pdf.svg');
}

.iconame-ft-txt {
  background-image: url('../assets/images/file-icons/txt.svg');
}

.iconame-ft-odp {
  background-image: url('../assets/images/file-icons/odp.svg');
}

.iconame-ft-ods {
  background-image: url('../assets/images/file-icons/ods.svg');
}

.iconame-ft-odt {
  background-image: url('../assets/images/file-icons/odt.svg');
}

.iconame-ft-otp {
  background-image: url('../assets/images/file-icons/otp.svg');
}

.iconame-ft-ots {
  background-image: url('../assets/images/file-icons/ots.svg');
}

.iconame-ft-ott {
  background-image: url('../assets/images/file-icons/ott.svg');
}

.iconame-ft-pot {
  background-image: url('../assets/images/file-icons/pot.svg');
}

.iconame-ft-potm {
  background-image: url('../assets/images/file-icons/potm.svg');
}

.iconame-ft-potx {
  background-image: url('../assets/images/file-icons/potx.svg');
}

.iconame-ft-pps {
  background-image: url('../assets/images/file-icons/pps.svg');
}

.iconame-ft-ppsm {
  background-image: url('../assets/images/file-icons/ppsm.svg');
}

.iconame-ft-ppsx {
  background-image: url('../assets/images/file-icons/ppsx.svg');
}

.iconame-ft-pptx {
  background-image: url('../assets/images/file-icons/pptx.svg');
}

.iconame-ft-ppt {
  background-image: url('../assets/images/file-icons/ppt.svg');
}

.iconame-ft-pptm {
  background-image: url('../assets/images/file-icons/pptm.svg');
}

.iconame-ft-rtf {
  background-image: url('../assets/images/file-icons/rtf.svg');
}

.iconame-ft-rtf {
  background-image: url('../assets/images/file-icons/rtf.svg');
}

// Zip Files
.iconame-ft-zip,
.iconame-ft-rar {
  background-image: url('../assets/images/file-icons/zip.svg');
}

// Exec files
.iconame-ft-exe,
.iconame-ft-dll {
  background-image: url('../assets/images/file-icons/exe.svg');
}

.iconame-ft-html,
.iconame-ft-htm,
.iconame-ft-xml,
.iconame-ft-xslt,
.iconame-ft-json {
  background-image: url('../assets/images/file-icons/html.svg');
}

// iWork files
.iconame-ft-numbers {
  background-image: url('../assets/images/file-icons/numbers.svg');
}

.iconame-ft-pages {
  background-image: url('../assets/images/file-icons/pages.svg');
}

.iconame-ft-key {
  background-image: url('../assets/images/file-icons/keynote.svg');
}

.iconame-ft-3gp,
.iconame-ft-aa,
.iconame-ft-aax,
.iconame-ft-act,
.iconame-ft-amr,
.iconame-ft-ape,
.iconame-ft-au,
.iconame-ft-awb,
.iconame-ft-dct,
.iconame-ft-dss,
.iconame-ft-dvf,
.iconame-ft-gsm,
.iconame-ft-m4a,
.iconame-ft-m4b,
.iconame-ft-m4p,
.iconame-ft-mmf,
.iconame-ft-mp3,
.iconame-ft-mpc,
.iconame-ft-msv,
.iconame-ft-nsf,
.iconame-ft-pcm,
.iconame-ft-wav,
.iconame-ft-aiff,
.iconame-ft-aac,
.iconame-ft-ogg,
.iconame-ft-wma,
.iconame-ft-flac,
.iconame-ft-alac {
  background-image: url('../assets/images/file-icons/audio.svg');
}

.iconame-ft-jpeg,
.iconame-ft-jpg,
.iconame-ft-jpe,
.iconame-ft-jfif,
.iconame-ft-jif,
.iconame-ft-jp2,
.iconame-ft-jpx,
.iconame-ft-j2k,
.iconame-ft-j2c,
.iconame-ft-fpx,
.iconame-ft-pcd,
.iconame-ft-gif,
.iconame-ft-bmp,
.iconame-ft-tiff,
.iconame-ft-tif,
.iconame-ft-png,
.iconame-ft-raw,
.iconame-ft-ico,
.iconame-ft-svg,
.iconame-ft-webp,
.iconame-ft-heif,
.iconame-ft-heifs,
.iconame-ft-heic,
.iconame-ft-heics,
.iconame-ft-avci,
.iconame-ft-avcs,
.iconame-ft-avif,
.iconame-ft-avifs {
  background-image: url('../assets/images/file-icons/image.svg');
}

.iconame-ft-webm,
.iconame-ft-mkv,
.iconame-ft-flv,
.iconame-ft-vob,
.iconame-ft-ogv,
.iconame-ft-ogg,
.iconame-ft-drc,
.iconame-ft-gifv,
.iconame-ft-mng,
.iconame-ft-avi,
.iconame-ft-mov,
.iconame-ft-qt,
.iconame-ft-wmv,
.iconame-ft-rm,
.iconame-ft-rmvb,
.iconame-ft-amv,
.iconame-ft-mp4,
.iconame-ft-mpg,
.iconame-ft-mp2,
.iconame-ft-mpeg,
.iconame-ft-mpe,
.iconame-ft-mpv,
.iconame-ft-3gp,
.iconame-ft-3g2 {
  background-image: url('../assets/images/file-icons/video.svg');
}
